import { useState } from 'react';
import Sundog from '../assets/13_FRONT COVER_WEB.jpg';
import { FaSpotify, FaDeezer } from "react-icons/fa";
import { SiApplemusic, SiTidal } from "react-icons/si";
import { TfiMoreAlt } from "react-icons/tfi";
/* import { useMediaQuery } from 'react-responsive';
 */


const Music = () => {

    const [open, setIsOpen] = useState(false);
    /* const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
    const isMobile = useMediaQuery({query: '(max-width: 800px'})
    const isNarrowScreen = window.matchMedia('(max-width: 500px').matches; */

    const services = [
        {
            title: "Spotify",
            logo: <FaSpotify />,
            link: "https://open.spotify.com/album/1rewDsz5nS7D6QO01orado?si=A5LNNbK8SICjtUVOwpk0tg"
        },
        {
            title: "Apple Music",
            logo: <SiApplemusic />,
            link: "https://music.apple.com/se/album/sun-dog/1727435354?l=en-GB"
        },
        {
            title: "Tidal",
            logo: <SiTidal />,
            link: "https://tidal.com/browse/album/341826456"
        },
        {
            title: "Deezer",
            logo: <FaDeezer />,
            link: "https://deezer.page.link/zLkjpJPygscrmxCo7"
        },
        {
            title: "More",
            logo: <TfiMoreAlt />,
            link: "http://li.sten.to/maidavale"
        }
    ]

    return(
        <>
        <div className='w-full flex flex-col items-center lg:pt-10'>
            <div className='pl-5 pr-5 pb-5 max-w-[320px] 2xl:max-w-[420px]'>
                <img src={Sundog}></img>
            </div>
            <div className='text-center pb-4'>
                <h2 className='text-base lg:text-xl xl:text-2xl'>MaidaVale - 'Sun Dog'</h2>
                <p className='text-sm md:text-base'>Choose your music service</p>
            </div>
            {services.map((service) => (
                <div className='boxes-container w-full max-w-[300px] 2xl:max-w-[400px] pb-3'>
                    <a href={service.link} target='_blank'>
                        <div className='box flex items-center justify-center'>
                            <div className='flex items-center gap-3 md:hover:services-hover'>
                                {service.logo}
                                <p className='text-sm'>{service.title}</p>
                            </div>
                        </div>
                    </a>
                </div>
            ))}
            <a className='underline text-lg lg:text-xl font-bold md:hover:hover-preorder p-3 z-10' target='_blank' href='https://li.sten.to/maidavale-sun-dog'>Buy Now!</a>
        </div>
        <div className='lg:absolute lg:bottom-0 w-full pl-7 pb-7 pr-7 flex flex-col pt-5 items-center md:flex-row md:justify-between'>
            <a target='_blank' href='https://www.instagram.com/silverdaggerrecords/' className='hover:links-hover text-center'>follow us on instagram</a>
            <div>
                <div className='flex flex-col pb-5'>
   {/*                  {open && (
                        <div className='credits-container'>
                            <div className='text-xs max-w-[220px] text-right'>Sun Dog was recorded and mixed at Svenska Grammofonstudion in Gothenburg between March and September 2023. 
                            <br className='mb-2'></br>
                            Produced by Kalle Gustafsson Jerneholm and György Barocsai. Engineered by György Barocsai and Kalle Gustafsson Jerneholm. Mixed by György Barocsai. 
                            <br className='mb-2'></br>
                            Mastered by Frida Claeson Johansson at Svenska Grammofonstudion Mastering Berlin. 
                            <br className='mb-2'></br>
                            All songs written by MaidaVale. 
                            <br className='mb-2'></br>
                            ‘Fools’ was mixed by John Agnello at The Rabbit Hole, Raleigh, NC. 
                            <br className='mb-2'></br>
                            Additional musicians: Said Ag Ayad, djembe and calabash, recorded by David Cukier at Studio Delta, Paris. Alexander Göransson, hand drums and shakers. 
                            <br className='mb-2'></br>
                            Cover art by Alex Khabbazi.</div>
                        </div>
                    )}
                    <button onClick={open ? () => setIsOpen(false) : () => setIsOpen(true)} className={`underline uppercase text-right md:hover:links-hover z-10 ${isTabletOrMobile ? "hidden" : "block" }`}>credits</button> */}
                    <a className='underline uppercase text-center md:text-right text-base pt-2 md:hover:links-hover' href='https://www.maidavaleband.com/' target='_blank'>Maidavaleband.com</a>
                </div>
               {/*  <p className='text-xs md:text-sm text-center'>© 2024 Silver Dagger Records</p> */}
            </div>
            
        </div>
        </>
    )
}

export default Music;