import './App.css';
import { BrowserRouter, Route, Routes  } from 'react-router-dom';
import Header from './components/Header';
import Music from './pages/Music';
import About from './pages/About';

function App() {
  return (
    <>
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path='/' element = {<Music/>}></Route>
        <Route path='/about' exact element = {<About/>}></Route>
      </Routes>
    </BrowserRouter> 
    </>
  );
}

export default App;
