import Logo from '../assets/Logo.svg';
import { Link } from 'react-router-dom';

const Header = () => {

  
 
    return(
        <>
        <div className='w-full pb-6 md:pb-0 pt-6 pr-6 pl-6 min-h-16 md:pl-10 md:pr-10 md:pt-10 flex justify-between items-start lg:fixed'>
            <div className='w-[100px] md:w-[120px] lg:w-[150px]'>
                <Link to='/'><img src={Logo}></img></Link>
                <button className='pr-2 text-sm' onClick={() =>  document.body.style.backgroundColor = "#F0E8E5" }>mono</button>
                <span className='text-sm'>/</span>
                <button onClick={ () => document.body.style.backgroundColor = "#07FFC4"} className='pl-2 pt-4 text-sm'>stereo</button>
            </div>
            <ul className='flex items-center gap-4 md:gap-6 text-sm md:text-base lg:text-lg'>
                <li>
                    <Link className='md:hover:underline' to='/'>music</Link>
                </li>
                <li>
                    <Link className='md:hover:underline' to='/about'>about</Link>
                </li>
            </ul>
        </div>
        </>
    )
}

export default Header;